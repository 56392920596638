import * as React from 'react'
import { Link } from 'react-router-dom'
import * as logo from '../assets/images/logo.png'

const ErrorPage = () => (
  <div>
    <div className="error-page">
      <div className="row">
        <div className="col-md-4 col-lg-4 col-md-offset-4 col-lg-offset-4">
          <Link to="/" className="hvr-pulse-grow">
            <img src={logo} className="big-logo" alt="City Invaders" />
          </Link>
          <h1>Error</h1>
          Oops! Page not found.
        </div>
      </div>
    </div>
  </div>
)

export default ErrorPage
