import createReducer from '../createReducer'
import { actions } from './socket_actions'

export interface ISocketState {
  connecting: boolean
  connected: boolean
  error: Error | null
  socket: any | null
}

const initialState: ISocketState = {
  connecting: false,
  connected: false,
  error: null,
  socket: null
}

export default createReducer<ISocketState>(initialState, {
  [actions.SOCKET_CONNECTING]: (state: ISocketState, { socket }) => ({ ...state, connecting: true, socket }),
  [actions.SOCKET_CONNECTED]: (state: ISocketState) => ({
    ...state,
    connecting: false,
    connected: true,
    error: null
  }),
  [actions.SOCKET_CONNECTION_ERROR]: (state: ISocketState) => ({
    ...state,
    connected: false,
    connecting: false,
    error: null
  })
})
