import Settings from '../Settings'
import * as socketClient from 'socket.io-client'
import { AnyAction, Dispatch } from 'redux'
import { autoAuth } from './account_actions'

// Actions types
export const actions = {
  SOCKET_CONNECTING: 'SOCKET_CONNECTING',
  SOCKET_CONNECTED: 'SOCKET_CONNECTED',
  SOCKET_CONNECTION_ERROR: 'SOCKET_CONNECTION_ERROR'
}

let socket: any = null
const timeout = 3000

export const socketConnect = () => (dispatch: Dispatch<AnyAction>) => {
  if (socket !== null) socket.disconnect()

  // Initiate Socket connection
  socket = socketClient(
    (Settings.socketServer.port === 443 ? 'wss' : 'ws') +
      '://' +
      Settings.socketServer.host +
      ':' +
      Settings.socketServer.port
  )

  dispatch({ type: actions.SOCKET_CONNECTING, socket })

  // Connection success
  socket.on('connect', () => {
    // /!\ The order of dispatch events counts, in order to avoid redirection by Dashboard on mount
    dispatch(autoAuth())
    dispatch({ type: actions.SOCKET_CONNECTED })
  })

  // Connection error
  for (const eventName of [
    'connect_error',
    'connect_timeout',
    'disconnect',
    'reconnect_error',
    'reconnect_failed'
  ]) {
    socket.on(eventName, () => dispatch({ type: actions.SOCKET_CONNECTION_ERROR }))
  }

  // Reconnection
  socket.on('reconnecting', () => dispatch({ type: actions.SOCKET_CONNECTING }))
}

export const socketEmit = (eventName: string, data?: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (socket === null) {
      return reject(new Error('Socket not connected'))
    }

    const timeoutId = setTimeout(() => reject(new Error('Request timed out')), timeout)

    socket.emit(eventName, data, response => {
      clearTimeout(timeoutId)
      if (response.error) {
        const error = new Error(response.error.message)
        error.name = response.error.name
        return reject(error)
      }
      resolve(response)
    })
  })
}
