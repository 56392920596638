import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { IRootState } from '../../redux'
// Components
import SideBar from './layout/SideBar'
import TopNav from './layout/TopNav'
import Scroll from 'react-scroll'

interface IProps extends RouteComponentProps<{}> {
  authenticated: boolean
  authenticating: boolean
}

interface IState {
  showMenu: boolean
}

class DashboardLayout extends React.PureComponent<IProps, IState> {
  public state: IState = {
    showMenu: false
  }

  public componentWillMount() {
    // If not logged in, redirects to login form
    if (!this.props.authenticated && !this.props.authenticating) {
      console.log('No current user => redirect to /')
      this.props.history.replace('/')
    }
  }

  public componentWillReceiveProps(nextProps: IProps) {
    // If not logged in, redirects to login form
    if (this.props.authenticated && !nextProps.authenticated && !nextProps.authenticating) {
      console.log('No current user => redirect to /')
      this.props.history.replace('/')
    }
    // Hide menu on page change
    if (
      nextProps.location.pathname !== this.props.location.pathname ||
      nextProps.location.search !== this.props.location.search
    ) {
      this.setState({ showMenu: false })
      Scroll.animateScroll.scrollToTop({ duration: 0 })
    }
  }

  public render() {
    if (!this.props.authenticated) return null
    return (
      <div className={'dashboard-page' + (this.state.showMenu ? ' push-right' : '')}>
        <TopNav onToggleMenu={this.toggleMenu} />
        <SideBar />
        <section id="body-container">{this.props.children}</section>
      </div>
    )
  }

  private toggleMenu = () => this.setState({ showMenu: !this.state.showMenu })
}

const mapStateToProps = ({ account }: IRootState) => ({
  authenticated: account.user !== null,
  authenticating: account.loading
})

export default withRouter(connect(mapStateToProps)(DashboardLayout))
