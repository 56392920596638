import * as React from 'react'
import { connect } from 'react-redux'
// Components
import LoadingSpinner from './common/LoadingSpinner'
// Actions
import { socketConnect } from '../redux/socket_actions'
import { IRootState } from '../redux'

interface IProps {
  children: any
  socketConnecting: boolean
  socketConnected: boolean
  socketError: any
  socketConnect: typeof socketConnect
}

class ServerConnection extends React.PureComponent<IProps> {
  public componentWillMount() {
    if (!this.props.socketConnecting && !this.props.socketConnected) {
      this.props.socketConnect()
    }
  }

  public render() {
    if (this.props.socketConnected) {
      return <div>{this.props.children}</div>
    }

    // Connection to Server screen
    return (
      <div className="connection-page">
        <div className="spinner">
          <LoadingSpinner opts={{ scale: 0.7, color: '#fff' }} />
        </div>
        <div className="message">
          {this.props.socketConnecting && 'Connection to server...'}
          {!this.props.socketConnecting && this.props.socketError && 'Connection error.'}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ socket }: IRootState) => ({
  socketConnecting: socket.connecting,
  socketConnected: socket.connected,
  socketError: socket.error
})

const mapDispatchToProps = {
  socketConnect
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerConnection)
