import createReducer from '../createReducer'
import { actions } from './account_actions'
import { IUser } from '@cityinvaders/dtos'

export interface IAccountState {
  loginForm: ILoginForm
  user: IUser | null
  loading: boolean
  loginError: Error | null
}

export interface ILoginForm {
  email: string
  password: string
}

const initialState: IAccountState = {
  loginForm: {
    email: '',
    password: ''
  },
  user: null,
  loading: false,
  loginError: null
}

export default createReducer<IAccountState>(initialState, {
  [actions.ACCOUNT_LOGIN_UPDATE_FORM]: (state, { data }) => ({
    ...state,
    loginForm: { ...state.loginForm, ...data }
  }),
  [actions.ACCOUNT_LOGIN_LOADING]: (state: IAccountState) => ({
    ...state,
    loading: true,
    loginError: null
  }),
  [actions.ACCOUNT_LOGIN_COMPLETE]: (state: IAccountState, { user }) => ({ ...initialState, user }),
  [actions.ACCOUNT_LOGIN_ERROR]: (state: IAccountState, { error }) => ({
    ...state,
    loading: false,
    loginError: error
  }),
  [actions.ACCOUNT_LOGOUT]: () => initialState
})
