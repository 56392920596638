// Libraries
import * as React from 'react'
import Spinner from 'react-spin'

const defaulLoadingSpinOpts = {
  lines: 9,
  length: 33,
  width: 16,
  radius: 36,
  scale: 0.1,
  corners: 1,
  color: '#000',
  opacity: 0.25,
  rotate: 11,
  speed: 1.6,
  trail: 59
}

const containerStyle = {
  width: 16,
  height: 16,
  position: 'relative',
  display: 'inline-block'
}

interface IProps {
  stopped?: boolean
  style?: any
  opts?: any
}

export default class LoadingSpinner extends React.PureComponent<IProps> {
  public render() {
    const { style, opts } = this.props
    const stopped = !!this.props.stopped
    const spanStyle = stopped ? { display: 'none' } : { ...containerStyle, ...style }
    const loadingSpinOpts = this.props.opts
      ? { ...defaulLoadingSpinOpts, ...opts }
      : defaulLoadingSpinOpts
    return (
      <span style={spanStyle}>
        <Spinner config={loadingSpinOpts} stopped={stopped} />
      </span>
    )
  }
}
