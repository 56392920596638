import * as React from 'react'
import { connect } from 'react-redux'
// Components
import { Panel, Checkbox, Button, FormControl, FormGroup, Alert } from 'react-bootstrap'
import LoadingSpinner from '../../common/LoadingSpinner'
// Actions
import { updateSearchForm, changeUrl } from './redux/usersSearch_actions'
import { IUsersSearchForm } from './redux/usersSearch_reducer'
import { IRootState } from '../../../redux'

interface IProps {
  form: IUsersSearchForm
  loading: boolean
  error: Error | null
  updateSearchForm: typeof updateSearchForm
  changeUrl: typeof changeUrl
}

class UsersSearch extends React.PureComponent<IProps> {
  public render() {
    const { form, loading, error } = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <Panel
          bsStyle="primary"
          header={
            <span>
              <span className="glyphicon glyphicon-search" style={{ marginRight: 15 }} />
              Search Users
            </span>
          }
          footer={
            <Button type="submit" bsSize="large" disabled={loading}>
              {loading && <LoadingSpinner opts={{ color: '#fff' }} style={{ marginRight: 10 }} />}
              Search
            </Button>
          }
        >
          <FormGroup bsSize="large">
            <FormControl
              type="text"
              placeholder="ID / Username / Email"
              className="underline"
              value={form.query}
              onChange={this.handleQueryChange}
            />
          </FormGroup>
          <Checkbox
            checked={form.includeDisabledUsers}
            onChange={this.handleIncludeDisabledUsersChange}
          >
            Include deleted users
          </Checkbox>

          {error && (
            <Alert bsStyle="danger">
              {error.name}: {error.message}
            </Alert>
          )}
        </Panel>
      </form>
    )
  }

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.props.updateSearchForm({ page: 1 })
    this.props.changeUrl()
  }

  private handleQueryChange = e => this.props.updateSearchForm({ query: e.target.value })
  private handleIncludeDisabledUsersChange = e =>
    this.props.updateSearchForm({ includeDisabledUsers: e.target.checked })
}

const mapStateToProps = ({ usersSearch }: IRootState) => ({
  form: usersSearch.searchForm,
  loading: usersSearch.loading,
  error: usersSearch.searchError
})

const mapDispatchToProps = {
  updateSearchForm,
  changeUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSearch)
