import { socketEmit } from '../../../../redux/socket_actions'
import { IUser } from '@cityinvaders/dtos'
import { IRootState } from '../../../../redux'

// Actions types
export const actions = {
  USER_EDIT_UPDATE_FORM: 'USER_EDIT_UPDATE_FORM',
  USER_EDIT_RESET: 'USER_EDIT_RESET',
  USER_EDIT_LOADING: 'USER_EDIT_LOADING',
  USER_EDIT_LOADING_ERROR: 'USER_EDIT_LOADING_ERROR',
  USER_EDIT_LOADED: 'USER_EDIT_LOADED',
  USER_SAVING: 'USER_SAVING',
  USER_SAVING_ERROR: 'USER_SAVING_ERROR',
  USER_SAVED: 'USER_SAVED'
}

export const updateUserForm = (data: Partial<IUser>) => ({
  type: actions.USER_EDIT_UPDATE_FORM,
  data
})

export const loadById = (id: string) => dispatch => {
  dispatch({ type: actions.USER_EDIT_LOADING, id })
  return socketEmit('Users.getById', { id })
    .then(response => dispatch({ type: actions.USER_EDIT_LOADED, user: response.users[0] }))
    .catch(error => dispatch({ type: actions.USER_EDIT_LOADING_ERROR, error }))
}

export const resetUserForm = () => ({ type: actions.USER_EDIT_RESET })

export const saveUserForm = () => (dispatch, getState: () => IRootState) => {
  const { userData, userForm } = getState().userEdit
  if (!userData || !userForm) return
  const changedData = {}
  Object.keys(userForm).forEach(k => {
    if (userForm[k] !== userData[k]) {
      changedData[k] = userForm[k]
    }
  })
  return dispatch(saveUser(userData.id, changedData))
}

export const saveUser = (id: string, changedData: Partial<IUser>) => dispatch => {
  if (Object.keys(changedData).length === 0) return

  dispatch({ type: actions.USER_SAVING, id })
  return socketEmit('Users.edit', { id, changedData })
    .then(response => dispatch({ type: actions.USER_SAVED, user: response.users[0] }))
    .catch(error => dispatch({ type: actions.USER_SAVING_ERROR, error }))
}
