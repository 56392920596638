import createReducer from '../../../../createReducer'
import { actions } from './battleEdit_actions'
import { actions as actionsDelete } from './battleDelete_actions'
import { IBattle, IBattleEvent } from '@cityinvaders/dtos'

export interface IBattleEditState {
  id: string | null
  battle: IBattle | null
  events: IBattleEvent[] | null
  loading: boolean
  loadingError: Error | null
}

const initialState: IBattleEditState = {
  id: null,
  battle: null,
  events: null,
  loading: false,
  loadingError: null
}

export default createReducer(initialState, {
  [actions.BATTLE_LOADING]: (state, { id }) => ({
    ...state,
    loading: true,
    loadingError: null,
    savingError: null,
    id
  }),
  [actions.BATTLE_LOADED]: (state, { battle, events }) => ({
    ...state,
    loading: false,
    battle,
    events,
    battleForm: { ...battle }
  }),
  [actions.BATTLE_LOADING_ERROR]: (state, { error }) => ({
    ...state,
    loading: false,
    loadingError: error
  }),

  [actionsDelete.BATTLE_DELETED]: (state, { ids }) => {
    if (ids.indexOf(state.id) === -1) return state
    const loadingError = new Error('Battle has been deleted.')
    return { ...state, battle: null, loadingError }
  }
})
