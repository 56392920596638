import * as React from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
// Components
import {
  Panel,
  Col,
  Row,
  Form,
  Button,
  FormControl,
  Modal,
  Label,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import UserCard from '../Users/UserCard'
import LoadingSpinner from '../../common/LoadingSpinner'
import { ObjectInspector } from 'react-inspector'
// Actions
import { deleteByIds } from './redux/battleDelete_actions'
import { IBattle, IBattleEvent } from '@cityinvaders/dtos'
import { IRootState } from '../../../redux'

interface IProps {
  id: string
  battle: IBattle | null
  events: IBattleEvent[] | null
  loading: boolean
  loadingError: Error | null
  deleting: boolean
  deleteByIds: typeof deleteByIds
}

interface IState {
  confirmDelete: boolean
}

class BattleEdit extends React.PureComponent<IProps, IState> {
  public state: IState = {
    confirmDelete: false
  }

  public render() {
    const { id, battle, events, loading, deleting, loadingError } = this.props

    if (loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: 60 }}>
          <LoadingSpinner opts={{ scale: 0.7 }} />
        </div>
      )
    }

    if (loadingError) {
      return (
        <Panel
          bsStyle="danger"
          header={
            <span>
              <span className="glyphicon glyphicon-battle" style={{ marginRight: 15 }} />
              {loadingError.message === 'NOT_FOUND' ? 'Battle Not Found' : loadingError.message}
            </span>
          }
        >
          <strong>Battle ID:</strong> {id}
        </Panel>
      )
    }

    if (!id || !battle || !events) return null

    return (
      <Form horizontal>
        <Panel
          bsStyle="primary"
          header={
            <span>
              <span className="glyphicon glyphicon-battle" style={{ marginRight: 15 }} />
              Battle
            </span>
          }
          footer={
            <div>
              <Button bsStyle="danger" onClick={this.handleDelete} disabled={deleting}>
                {deleting && (
                  <LoadingSpinner opts={{ color: '#fff' }} style={{ marginRight: 10 }} />
                )}
                Delete
              </Button>
            </div>
          }
        >
          <Row>
            <Col sm={4} md={2}>
              Id
            </Col>
            <Col sm={8} md={10}>
              <FormControl type="text" value={id} disabled className="underline" />
            </Col>
          </Row>

          <Row>
            <Col sm={4} md={2}>
              Map
            </Col>
            <Col sm={8} md={10}>
              {battle.map_name}
            </Col>
          </Row>

          <Row>
            <Col sm={4} md={2}>
              Goal
            </Col>
            <Col sm={8} md={10}>
              {battle.goal}
              {battle.quickmatch && (
                <Label bsStyle="warning" style={{ marginLeft: 5 }}>
                  Quickmatch
                </Label>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={4} md={2}>
              Start
            </Col>
            <Col sm={8} md={10}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={'tooltip-battle-start_time' + battle.id}>
                    {moment(battle.start_time).format('LLL')}
                  </Tooltip>
                }
              >
                <span>
                  {moment(battle.start_time).fromNow()}
                  <i className="glyphicon glyphicon-info-sign" style={{ marginLeft: 5 }} />
                </span>
              </OverlayTrigger>
            </Col>
          </Row>

          <Row>
            <Col sm={4} md={2}>
              End
            </Col>
            <Col sm={8} md={10}>
              {battle.end_time ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-battle-end_time' + battle.id}>
                      {moment(battle.end_time).format('LLL')}
                    </Tooltip>
                  }
                >
                  <span>
                    {moment(battle.end_time).fromNow()}
                    <i className="glyphicon glyphicon-info-sign" style={{ marginLeft: 5 }} />
                  </span>
                </OverlayTrigger>
              ) : (
                <em>Not finished</em>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20, marginBottom: 30 }}>
            {battle.users_ids.map(userId => (
              <Col md={6} key={'link-' + userId}>
                <UserCard id={userId} />
              </Col>
            ))}
          </Row>

          <strong>Events:</strong>
          {events.map((event, i) => (
            <ObjectInspector
              key={'event' + i}
              name={event.Type}
              data={event[event.Type + 'Event']}
            />
          ))}
        </Panel>

        {this.renderConfirmDelete()}
      </Form>
    )
  }

  private renderConfirmDelete() {
    const { id } = this.props
    if (!this.state.confirmDelete || !id) return null

    return (
      <Modal show backdrop onHide={this.handleCancelDelete}>
        <Modal.Header>
          <Modal.Title>Delete Battle</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure to delete this battle?</p>
          <ul className="list-battles">
            <li>
              <strong>{id}</strong>
            </li>
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.handleCancelDelete}>Cancel</Button>
          <Button onClick={this.handleConfirmDelete} bsStyle="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  private handleDelete = () => this.setState({ confirmDelete: true })
  private handleCancelDelete = () => this.setState({ confirmDelete: false })

  private handleConfirmDelete = () => {
    this.props.deleteByIds([this.props.id])
    this.setState({ confirmDelete: false })
  }
}

const mapStateToProps = ({ battleEdit, battleDelete }: IRootState) => ({
  id: battleEdit.id,
  loading: battleEdit.loading,
  loadingError: battleEdit.loadingError,
  deleting: battleDelete.loading,
  battle: battleEdit.battle,
  events: battleEdit.events
})

const mapDispatchToProps = {
  deleteByIds
}

export default connect(mapStateToProps, mapDispatchToProps)(BattleEdit)
