import * as React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'

const rootPath = '/dashboard/'

interface IProps extends RouteComponentProps<{}> {
  children: React.ReactNode
  to: string
  icon: string
}

class SideBarLink extends React.PureComponent<IProps> {
  public render() {
    const { children, to, icon, location: { pathname } } = this.props
    const active = to === rootPath ? pathname === rootPath : pathname.indexOf(to) === 0
    return (
      <li className={active ? 'active' : ''}>
        <Link to={to}>
          <span className={'glyphicon glyphicon-' + icon} />
          {children}
        </Link>
      </li>
    )
  }
}

export default withRouter(SideBarLink)
