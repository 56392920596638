import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import HomeBattlesGraph from './HomeBattlesGraph'

const Home = () => (
  <div>
    <Row>
      <Col md={12} lg={5}>
        <HomeBattlesGraph />
      </Col>
    </Row>
  </div>
)

export default Home
