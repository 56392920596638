import { AnyAction, Reducer } from 'redux'

export interface IReducerHandler<TState> {
  [type: string]: Reducer<TState>
}

const createReducer = <TState>(initialState: TState, handlers: IReducerHandler<TState>) => (
  state = initialState,
  action: AnyAction
): TState => (action.type && handlers[action.type] ? handlers[action.type](state, action) : state)

export default createReducer