import { socketEmit } from '../../../../redux/socket_actions'

// Actions types
export const actions = {
  BATTLE_DELETING: 'BATTLE_DELETING',
  BATTLE_DELETING_ERROR: 'BATTLE_DELETING_ERROR',
  BATTLE_DELETED: 'BATTLE_DELETED'
}

export const deleteByIds = (ids: string[]) => dispatch => {
  dispatch({ type: actions.BATTLE_DELETING })
  return socketEmit('Battles.deleteByIds', { ids })
    .then(() => dispatch({ type: actions.BATTLE_DELETED, ids }))
    .catch(error => dispatch({ type: actions.BATTLE_DELETING_ERROR, error }))
}
