import * as React from 'react'
import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
// Store & History
import store from './store'
import history from './history'
// Components
import ServerConnection from './components/ServerConnection'
import DashboardLayout from './components/dashboard/DashboardLayout'
import ErrorPage from './components/ErrorPage'
import LoginPage from './components/LoginPage'
import HomePage from './components/dashboard/Home/HomePage'
import UsersPage from './components/dashboard/Users/UsersPage'
import BattlesPage from './components/dashboard/Battles/BattlesPage'
// Style
import './styles/index.less'

const App = () => (
  <Provider store={store}>
    <ServerConnection>
      <Router history={history}>
        <Switch>
          <Route path="/dashboard">
            <DashboardLayout>
              <Switch>
                <Route exact path="/dashboard/" component={HomePage} />
                <Route exact path="/dashboard/users" component={UsersPage} />
                <Route exact path="/dashboard/users/:userId" component={UsersPage} />
                <Route exact path="/dashboard/battles" component={BattlesPage} />
                <Route exact path="/dashboard/battles/:battleId" component={BattlesPage} />
                <Route component={ErrorPage} />
              </Switch>
            </DashboardLayout>
          </Route>
          <Route exact path="/" component={LoginPage} />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </ServerConnection>
  </Provider>
)

export default App
