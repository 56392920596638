import * as React from 'react'
import { connect } from 'react-redux'
// Actions
import { changeUrl } from './redux/usersSearch_actions'

interface IProps {
  id: string
  username: string
}

interface IReduxProps {
  changeUrl: typeof changeUrl
}

class UserLink extends React.PureComponent<IProps & IReduxProps> {
  public render() {
    return (
      <a href="#" onClick={this.handleClick}>
        {this.props.username}
      </a>
    )
  }

  private handleClick = e => {
    e.preventDefault()
    this.props.changeUrl(this.props.id)
  }
}

const mapDispatchToProps = {
  changeUrl
}

export default connect(undefined, mapDispatchToProps)(UserLink)
