import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Checkbox } from 'react-bootstrap'

interface IProps {
  indeterminate: boolean
  [key: string]: any
}

const CheckboxIndeterminate = (props: IProps) => {
  const { indeterminate, ...checkboxProps } = props
  return (
    <Checkbox
      {...checkboxProps}
      ref={checkbox => {
        if (checkbox === null) return
        const domNode = ReactDOM.findDOMNode(checkbox)
        const checkboxes = domNode.getElementsByTagName('input')
        checkboxes[0].indeterminate = indeterminate
      }}
    />
  )
}

export default CheckboxIndeterminate
