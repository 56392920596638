import account, { IAccountState } from './account_reducer'
import socket, { ISocketState } from './socket_reducer'
import users, { IUsersState } from '../components/dashboard/Users/redux'
import battles, { IBattlesState } from '../components/dashboard/Battles/redux'
import home, { IHomeState } from '../components/dashboard/Home/redux'

export default {
  account,
  socket,
  ...home,
  ...users,
  ...battles
}

export interface IRootState extends IUsersState, IBattlesState, IHomeState {
  account: IAccountState
  socket: ISocketState
}
