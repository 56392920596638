import createReducer from '../../../../createReducer'
import { actions } from './usersCollection_actions'
import { actions as actionsEdit } from './userEdit_actions'
import { actions as actionsDelete } from './userDelete_actions'
import { IUser } from '@cityinvaders/dtos'

interface IUserEntry {
  id: string
  user: IUser
  loading: boolean
  error: Error | null
}

export type IUsersCollectionState = IUserEntry[]

// Array of { id, user, loading, error }
const initialState: IUsersCollectionState = []

const deleteUser = (state, id) => state.filter(u => u.id !== id)
const deleteUsers = (state, ids) => state.filter(u => ids.indexOf(u.id) === -1)
const addUser = (state, user) => deleteUser(state, user.id).concat(user)

export default createReducer(initialState, {
  [actions.USER_COLLECTION_LOADING]: (state, { id }) => addUser(state, { id, loading: true }),
  [actions.USER_COLLECTION_LOADED]: (state, { user }) => addUser(state, { id: user.id, user }),
  [actions.USER_COLLECTION_LOADING_ERROR]: (state, { id, error }) => addUser(state, { id, error }),

  [actionsDelete.USER_DELETED]: (state, { ids }) => deleteUsers(state, ids),
  [actionsEdit.USER_SAVED]: (state, { user }) => deleteUser(state, user.id)
})
