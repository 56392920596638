import createReducer from '../../../../createReducer'
import { actions } from './homeBattles_actions'
import { ISearchBattle } from '@cityinvaders/dtos'

export interface IHomeBattlesState {
  searchForm: IHomeBattlesSearchForm
  searchData: IHomeBattlesSearchForm | null
  battles: ISearchBattle[]
  loading: boolean
  searchError: Error | null
}

interface IHomeBattlesSearchForm {
  limit: number
  orderBy: string
}

const initialState: IHomeBattlesState = {
  searchForm: {
    limit: 1000,
    orderBy: 'start_time'
  },
  searchData: null,
  battles: [],
  loading: false,
  searchError: null
}

export default createReducer(initialState, {
  [actions.HOME_BATTLES_LOADING]: (state, { searchData }) => ({
    ...state,
    loading: true,
    searchError: null,
    searchData
  }),
  [actions.HOME_BATTLES_LOADED]: (state, { battles, totalCount }) => ({
    ...state,
    loading: false,
    battles,
    totalCount
  }),
  [actions.HOME_BATTLES_ERROR]: (state, { searchError }) => ({
    ...state,
    loading: false,
    searchError
  })
})
