import * as cookie from 'cookies-js'
import { AnyAction, Dispatch } from 'redux'
import { socketEmit } from './socket_actions'
import { ILoginForm } from './account_reducer'

// Actions types
export const actions = {
  ACCOUNT_LOGIN_LOADING: 'ACCOUNT_LOGIN_LOADING',
  ACCOUNT_LOGIN_COMPLETE: 'ACCOUNT_LOGIN_COMPLETE',
  ACCOUNT_LOGIN_ERROR: 'ACCOUNT_LOGIN_ERROR',
  ACCOUNT_LOGIN_UPDATE_FORM: 'ACCOUNT_LOGIN_UPDATE_FORM',
  ACCOUNT_LOGOUT: 'ACCOUNT_LOGOUT'
}

const cookieSettings = {
  path: '/',
  expires: 3600 * 24 * 60
}

export const updateLoginForm = (data: Partial<ILoginForm>) => ({
  type: actions.ACCOUNT_LOGIN_UPDATE_FORM,
  data
})

const loading = () => ({ type: actions.ACCOUNT_LOGIN_LOADING })

export const auth = ({email, password}: ILoginForm) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(loading())
  return socketEmit('Users.auth', { email, password, playing: false })
    .then((response: any) => {
      const user = response.users[0]
      if (!user.admin) throw new Error('ACCESS_DENIED')
      dispatch({ type: actions.ACCOUNT_LOGIN_COMPLETE, user })
      cookie.set('sessionId', response.sessionId, cookieSettings)
    })
    .catch(error => dispatch({ type: actions.ACCOUNT_LOGIN_ERROR, error }))
}

export const logout = (dontDeleteSession?: boolean) => (dispatch: Dispatch<AnyAction>) => {
  if (!dontDeleteSession) {
    cookie.expire('sessionId', cookieSettings)
  }
  return socketEmit('Users.logout')
    .then((response: any) => dispatch({ type: actions.ACCOUNT_LOGOUT }))
    .catch((error: any) => console.error('Login error', error))
}

export const autoAuth = () => (dispatch: Dispatch<AnyAction>) => {
  const sessionId = cookie.get('sessionId')
  if (!sessionId) return
  dispatch(loading())
  return socketEmit('Users.authBySession', { sessionId, playing: false })
    .then((response: any) => {
      const user = response.users[0]
      if (!user.admin) throw new Error('ACCESS_DENIED')
      dispatch({ type: actions.ACCOUNT_LOGIN_COMPLETE, user })
    })
    .catch(error => {
      console.error('Auto Auth error', error)
      dispatch({ type: actions.ACCOUNT_LOGIN_ERROR, error })
      dispatch(logout(error.message !== 'AUTH_FAILED'))
    })
}
