import * as React from 'react'
import { connect } from 'react-redux'
// Components
import { FormControl, FormGroup, Button, Alert } from 'react-bootstrap'
import LoadingSpinner from './common/LoadingSpinner'
// Actions
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { auth, updateLoginForm } from '../redux/account_actions'
import { ILoginForm } from '../redux/account_reducer'
import * as logo from '../assets/images/logo.png'
import { IRootState } from '../redux'

interface IProps {
  formData: ILoginForm
  loading: boolean
  error: Error | null
  authenticated: boolean
  updateForm: typeof updateLoginForm
  onSubmit: typeof auth
}

class LoginPage extends React.PureComponent<IProps & RouteComponentProps<{}>> {
  public componentWillMount() {
    // If logged in, redirects to dashboard
    if (this.props.authenticated) {
      console.log('Logged in => redirect to /dashboard/')
      this.props.history.replace('/dashboard/')
    }
  }

  public componentWillReceiveProps(nextProps: Partial<IProps>) {
    // If logged in, redirects to dashboard
    if (!this.props.authenticated && nextProps.authenticated) {
      console.log('Logged in => redirect to /dashboard/')
      this.props.history.replace('/dashboard/')
    }
  }

  public render() {
    const { formData, loading, error } = this.props
    const errorMessage = error ? <Alert bsStyle="danger">{this.getErrorMessage(error)}</Alert> : ''

    return (
      <div>
        <div className="login-page">
          <div className="row">
            <div className="col-md-4 col-lg-4 col-md-offset-4 col-lg-offset-4">
              <img src={logo} className="big-logo" alt="City Invaders" />

              <h1>Admin</h1>
              <br />

              <form role="form" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <FormControl
                    type="text"
                    className="form-control input-underline input-lg"
                    placeholder="Email"
                    value={formData.email}
                    onChange={this.handleEmailChange}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    type="password"
                    className="form-control input-underline input-lg"
                    placeholder="Password"
                    value={formData.password}
                    onChange={this.handlePasswordChange}
                  />
                </FormGroup>

                {errorMessage}

                <Button type="submit" disabled={loading}>
                  {loading && (
                    <LoadingSpinner opts={{ color: '#fff' }} style={{ marginRight: 10 }} />
                  )}
                  Log in
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private handleEmailChange = e => this.props.updateForm({ email: e.target.value })
  private handlePasswordChange = e => this.props.updateForm({ password: e.target.value })

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.props.onSubmit(this.props.formData)
  }

  private getErrorMessage(error: Error) {
    if (error.message === 'AUTH_FAILED') return 'Invalid email or password.'
    if (error.message === 'ACCESS_DENIED')
      return 'You must be admin to access this wonderful dashboard :3'
    return 'An error occurred: ' + error.message
  }
}

const mapStateToProps = ({ account }: IRootState) => ({
  formData: account.loginForm,
  loading: account.loading,
  error: account.loginError,
  authenticated: account.user !== null
})

const mapDispatchToProps = {
  updateForm: updateLoginForm,
  onSubmit: auth
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage))
