import createReducer from '../../../../createReducer'
import { actions } from './battlesSearch_actions'
import { actions as actionsDelete } from './battleDelete_actions'
import { cloneDeep } from 'lodash'
import { ISearchBattle } from '@cityinvaders/dtos'

export interface IBattlesSearchState {
  searchForm: IBattlesSearchForm
  searchData: IBattlesSearchForm | null
  battles: ISearchCollectionBattle[] | null
  totalCount: number
  loading: boolean
  searchError: Error | null
}

export interface IBattlesSearchForm {
  query: string
  page: number
  limit: number
  orderBy: string | undefined
  startedFrom?: string
  startedTo?: string
}

export type ISearchCollectionBattle = ISearchBattle & {
  selected: boolean | undefined
  deleted: boolean | undefined
}

const initialState: IBattlesSearchState = {
  searchForm: {
    query: '',
    page: 1,
    limit: 25,
    orderBy: '-start_time'
  },
  searchData: null,
  battles: null,
  totalCount: 0,
  loading: false,
  searchError: null
}

export default createReducer(initialState, {
  [actions.BATTLES_UPDATE_SEARCH_FORM]: (state, { data }) => {
    // Ensure page and limit are numbers
    if (typeof data.page !== 'undefined') data.page = Number(data.page)
    if (typeof data.limit !== 'undefined') data.limit = Number(data.limit)
    // Merge new data into form data
    return { ...state, searchForm: { ...state.searchForm, ...data } }
  },

  [actions.BATTLES_LOADING]: (state, { searchData }) => ({
    ...state,
    loading: true,
    searchError: null,
    searchData
  }),
  [actions.BATTLES_LOADED]: (state, { battles, totalCount }) => ({
    ...state,
    loading: false,
    battles,
    totalCount
  }),
  [actions.BATTLES_ERROR]: (state, { searchError }) => ({ ...state, loading: false, searchError }),

  [actions.BATTLES_SELECT_TOGGLE_BY_ID]: (state, { id }) => {
    const stateClone = cloneDeep(state)
    if (!stateClone.battles) return state
    const battle = stateClone.battles.find(u => u.id === id)
    if (!battle) return state
    battle.selected = !battle.selected
    return stateClone
  },

  [actions.BATTLES_SELECT_TOGGLE_ALL]: state => {
    const stateClone = cloneDeep(state)
    if (!stateClone.battles) return state
    stateClone.battles.forEach(battle => {
      battle.selected = !battle.selected
    })
    return stateClone
  },

  [actionsDelete.BATTLE_DELETED]: (state, { ids }) => {
    const stateClone = cloneDeep(state)
    if (!stateClone.battles) return state
    stateClone.battles.forEach(battle => {
      if (ids.indexOf(battle.id) === -1) return
      battle.deleted = true
    })
    return stateClone
  }
})
