import createReducer from '../../../../createReducer'
import { actions } from './usersSearch_actions'
import { actions as actionsEdit } from './userEdit_actions'
import { actions as actionsDelete } from './userDelete_actions'
import { cloneDeep } from 'lodash'
import { ISearchUser } from '@cityinvaders/dtos'

export interface IUsersSearchState {
  searchForm: IUsersSearchForm
  searchData: IUsersSearchForm | null
  users: ISearchCollectionUser[] | null
  totalCount: number
  loading: boolean
  searchError: Error | null
}

export type ISearchCollectionUser = ISearchUser & {
  selected: boolean | undefined
  deleted: boolean | undefined
}

export interface IUsersSearchForm {
  query: string
  includeDisabledUsers: boolean
  page: number
  limit: number
  orderBy: string | null
}

const initialState: IUsersSearchState = {
  searchForm: {
    query: '',
    includeDisabledUsers: false,
    page: 1,
    limit: 25,
    orderBy: null
  },
  searchData: null,
  users: null,
  totalCount: 0,
  loading: false,
  searchError: null
}

export default createReducer(initialState, {
  [actions.USERS_UPDATE_SEARCH_FORM]: (state, { data }) => {
    // Ensure page and limit are numbers
    if (typeof data.page !== 'undefined') data.page = Number(data.page)
    if (typeof data.limit !== 'undefined') data.limit = Number(data.limit)
    // Merge new data into form data
    return { ...state, searchForm: { ...state.searchForm, ...data } }
  },

  [actions.USERS_LOADING]: (state, { searchData }) => ({
    ...state,
    loading: true,
    searchError: null,
    searchData
  }),
  [actions.USERS_LOADED]: (state, { users, totalCount }) => ({
    ...state,
    loading: false,
    users,
    totalCount
  }),
  [actions.USERS_ERROR]: (state, { searchError }) => ({ ...state, loading: false, searchError }),

  [actions.USERS_SELECT_TOGGLE_BY_ID]: (state, { id }) => {
    const stateClone = cloneDeep(state)
    if (!stateClone.users) return state
    const user = stateClone.users.find(u => u.id === id)
    if (!user) return state
    user.selected = !user.selected
    return stateClone
  },

  [actions.USERS_SELECT_TOGGLE_ALL]: state => {
    const stateClone = cloneDeep(state)
    if (!stateClone.users) return state
    stateClone.users.forEach(user => {
      user.selected = !user.selected
    })
    return stateClone
  },

  [actionsDelete.USER_DELETED]: (state, { ids, forceDelete }) => {
    const stateClone = cloneDeep(state)
    const disabled = new Date().toISOString()
    if (!stateClone.users) return state
    stateClone.users.forEach(user => {
      if (ids.indexOf(user.id) === -1) return
      if (forceDelete) user.deleted = true
      else user.disabled = disabled
    })
    return stateClone
  },

  [actionsEdit.USER_SAVED]: (state, { user }) => {
    const stateClone = cloneDeep(state)
    if (!stateClone.users) return state
    const listUser = stateClone.users.find(u => u.id === user.id)
    if (!listUser) return state
    Object.keys(listUser).forEach(k => {
      if (typeof listUser[k] !== 'undefined' && typeof user[k] !== 'undefined') {
        listUser[k] = user[k]
      }
    })
    return stateClone
  }
})
