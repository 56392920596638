import * as React from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
// Components
import { Col, Checkbox, FormGroup, FormControl } from 'react-bootstrap'
// Actions
import { updateUserForm } from './redux/userEdit_actions'
import { IRootState } from '../../../redux'

interface IProps {
  field: string
  type: string
  label: string
  placeholder?: string
}

interface IReduxProps {
  value: any
  originalValue: any
  updateUserForm: typeof updateUserForm
}

class UserInputField extends React.PureComponent<IProps & IReduxProps> {
  public render() {
    const { field, type, label, placeholder } = this.props
    let { value, originalValue } = this.props

    if (typeof value === 'undefined') {
      console.error('Undefined field ' + field + ' in user')
    }

    // Fix value
    if (value == null) value = ''
    if (originalValue == null) originalValue = ''
    if (type === 'date') {
      value = moment(value).format('YYYY-MM-DD')
      originalValue = moment(originalValue).format('YYYY-MM-DD')
    } else if (type === 'datetime' || type === 'datetime-local') {
      value = moment(value).format('YYYY-MM-DDTHH:mm:ss')
      originalValue = moment(originalValue).format('YYYY-MM-DDTHH:mm:ss')
    } else if (type === 'number') {
      value = Number(value)
      originalValue = Number(originalValue)
    }

    const formGroupClass = originalValue === value ? '' : 'changed'

    if (type === 'checkbox') {
      return (
        <FormGroup className={formGroupClass}>
          <Col smOffset={4} sm={8}>
            <Checkbox checked={!!value} onChange={this.handleCheckboxChange}>
              {label}
            </Checkbox>
          </Col>
        </FormGroup>
      )
    }

    return (
      <FormGroup className={formGroupClass}>
        <Col sm={4}>{label}</Col>
        <Col sm={8}>
          <FormControl
            type={type}
            placeholder={placeholder}
            className="underline"
            value={value}
            onChange={this.handleInputChange}
          />
        </Col>
      </FormGroup>
    )
  }

  private handleCheckboxChange = (e: React.ChangeEvent<Checkbox>) =>
    this.handleChange(!!(e as any).target.checked)

  private handleInputChange = (e: React.ChangeEvent<FormControl>) =>
    this.handleChange((e as any).target.value)

  private handleChange = value => {
    const { field, type } = this.props
    if (type === 'number') {
      value = Number(value)
    } else if (type === 'datetime' || type === 'datetime-local') {
      value = moment(value).toISOString()
    }
    this.props.updateUserForm({ [field]: value })
  }
}

const mapStateToProps = ({ userEdit }: IRootState, props: IProps) => ({
  value: userEdit.userForm && userEdit.userForm[props.field],
  originalValue: userEdit.userData && userEdit.userData[props.field]
})

const mapDispatchToProps = {
  updateUserForm
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInputField)
