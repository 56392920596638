import createReducer from '../../../../createReducer'
import { actions } from './battleDelete_actions'

export interface IBattleDeleteState {
  loading: boolean
  error: Error | null
}

const initialState: IBattleDeleteState = {
  loading: false,
  error: null
}

export default createReducer(initialState, {
  [actions.BATTLE_DELETING]: state => ({ ...state, loading: true, error: null }),
  [actions.BATTLE_DELETED]: (state, { ids }) => ({ ...state, loading: false }),
  [actions.BATTLE_DELETING_ERROR]: (state, { error }) => ({ ...state, loading: false, error })
})
