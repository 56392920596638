interface ISettings {
  debug: boolean
  socketServer: {
    host: string
    port: number
  }
  devServerPort: number
}

const Settings: ISettings = {
  debug: false,
  socketServer: {
    host: 'api.cityinvaders.game',
    port: 443
  },
  devServerPort: 8080
}

export default Settings
