import * as qs from 'qs'
import history from '../../../../history'
import { socketEmit } from '../../../../redux/socket_actions'
import { IBattlesSearchForm } from './battlesSearch_reducer'
import { IRootState } from '../../../../redux'

// Actions types
export const actions = {
  BATTLES_LOADING: 'BATTLES_LOADING',
  BATTLES_LOADED: 'BATTLES_LOADED',
  BATTLES_ERROR: 'BATTLES_ERROR',
  BATTLES_UPDATE_SEARCH_FORM: 'BATTLES_UPDATE_SEARCH_FORM',
  BATTLES_SELECT_TOGGLE_BY_ID: 'BATTLES_SELECT_TOGGLE_BY_ID',
  BATTLES_SELECT_TOGGLE_ALL: 'BATTLES_SELECT_TOGGLE_ALL'
}

export const updateSearchForm = (data: Partial<IBattlesSearchForm>) => ({
  type: actions.BATTLES_UPDATE_SEARCH_FORM,
  data
})

export const search = (searchForm: IBattlesSearchForm) => dispatch => {
  dispatch({ type: actions.BATTLES_LOADING, searchData: searchForm })
  return socketEmit('Battles.search', searchForm)
    .then(response =>
      dispatch({
        type: actions.BATTLES_LOADED,
        battles: response.battles,
        totalCount: response.totalCount
      })
    )
    .catch(searchError => dispatch({ type: actions.BATTLES_ERROR, searchError }))
}

export const selectToggleById = (id: string) => ({ type: actions.BATTLES_SELECT_TOGGLE_BY_ID, id })
export const selectToggleAll = () => ({ type: actions.BATTLES_SELECT_TOGGLE_ALL })

// Push new URL to history with search form data and optional battleId
// If battleId is omitted, battle id in URL doesn't change
export const changeUrl = (newBattleId?: string) => (dispatch, getState: () => IRootState) => {
  const { searchForm } = getState().battlesSearch
  const battleId = typeof newBattleId === 'string' ? newBattleId : getState().battleEdit.id
  const locationQuery = {
    query: searchForm.query,
    limit: searchForm.limit,
    page: searchForm.page,
    orderBy: searchForm.orderBy
  }
  history.push({
    pathname: '/dashboard/battles' + (battleId ? '/' + battleId : ''),
    search: qs.stringify(locationQuery)
  })
}
