import { socketEmit } from '../../../../redux/socket_actions'

// Actions types
export const actions = {
  BATTLE_LOADING: 'BATTLE_LOADING',
  BATTLE_LOADING_ERROR: 'BATTLE_LOADING_ERROR',
  BATTLE_LOADED: 'BATTLE_LOADED'
}

export const loadById = (id: string) => async dispatch => {
  dispatch({ type: actions.BATTLE_LOADING, id })
  try {
    const { battles } = await socketEmit('Battles.getById', { id })
    const { BattleEvents } = await socketEmit('BattlesEvents.getByBattleId', { battleId: id })
    dispatch({ type: actions.BATTLE_LOADED, battle: battles[0], events: BattleEvents })
  } catch (error) {
    dispatch({ type: actions.BATTLE_LOADING_ERROR, error })
  }
}
