import * as React from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
// Components
import {
  Panel,
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  FormControl,
  Alert,
  OverlayTrigger,
  Tooltip,
  Modal
} from 'react-bootstrap'
import UserInputField from './UserInputField'
import LoadingSpinner from '../../common/LoadingSpinner'
// Actions
import { updateUserForm, resetUserForm, saveUserForm } from './redux/userEdit_actions'
import { disableByIds } from './redux/userDelete_actions'
import { IUserEditState } from './redux/userEdit_reducer'
import { IRootState } from '../../../redux'

interface IProps {
  userEdit: IUserEditState
  deleting: boolean
  updateUserForm: typeof updateUserForm
  resetUserForm: typeof resetUserForm
  saveUserForm: typeof saveUserForm
  disableByIds: typeof disableByIds
}

interface IState {
  confirmDelete: boolean
}

class UserEdit extends React.PureComponent<IProps, IState> {
  public state: IState = {
    confirmDelete: false
  }

  public render() {
    const {
      userEdit: { id, loading, loadingError, saving, savingError, userData, userForm },
      deleting
    } = this.props
    const disabled = userForm ? userForm.disabled : null
    const admin = userForm ? userForm.admin : false

    if (loading) {
      return (
        <div style={{ textAlign: 'center', marginTop: 60 }}>
          <LoadingSpinner opts={{ scale: 0.7 }} />
        </div>
      )
    }

    if (loadingError) {
      return (
        <Panel
          bsStyle="danger"
          header={
            <span>
              <span className="glyphicon glyphicon-user" style={{ marginRight: 15 }} />
              {loadingError.message === 'NOT_FOUND' ? 'User Not Found' : loadingError.message}
            </span>
          }
        >
          <strong>User ID:</strong> {id}
        </Panel>
      )
    }

    if (!id || !userData) return null

    return (
      <Form horizontal>
        <Panel
          bsStyle="primary"
          header={
            <span>
              <span className="glyphicon glyphicon-user" style={{ marginRight: 15 }} />
              {userData.username}
            </span>
          }
          footer={
            <div>
              <Button bsStyle="default" onClick={this.handleReset} disabled={deleting || saving}>
                Reset
              </Button>

              <Button bsStyle="danger" onClick={this.handleDelete} disabled={deleting || saving}>
                {deleting && (
                  <LoadingSpinner opts={{ color: '#fff' }} style={{ marginRight: 10 }} />
                )}
                Delete
              </Button>

              <Button
                bsStyle={disabled ? 'success' : 'warning'}
                onClick={this.handleToggleDisable}
                disabled={deleting || saving}
              >
                {disabled ? 'Enable' : 'Disable'}
              </Button>

              <Button
                bsStyle="success"
                onClick={this.props.saveUserForm}
                disabled={deleting || saving}
              >
                {saving && <LoadingSpinner opts={{ color: '#fff' }} style={{ marginRight: 10 }} />}
                Save
              </Button>
            </div>
          }
        >
          <Row>
            <Col md={6}>
              <FormGroup>
                <Col sm={4}>Id</Col>
                <Col sm={8}>
                  <FormControl type="text" value={id} disabled className="underline" />
                </Col>
              </FormGroup>

              <UserInputField
                field="username"
                type="text"
                label="Username"
                placeholder="Username"
              />

              {disabled && (
                <Alert bsStyle="warning">
                  This user has been disabled{' '}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={'tooltip-user-disabled' + id}>
                        {moment(disabled).format('LLL')}
                      </Tooltip>
                    }
                  >
                    <span>
                      {moment(disabled).fromNow()}
                      <i className="glyphicon glyphicon-info-sign" style={{ marginLeft: 5 }} />
                    </span>
                  </OverlayTrigger>
                </Alert>
              )}

              <UserInputField field="admin" type="checkbox" label="Admin" />

              {admin && <Alert bsStyle="warning">This user is Admin</Alert>}

              <UserInputField field="email" type="text" label="Email" placeholder="Email" />

              {savingError &&
                savingError.message === 'EMAIL_ALREADY_USED' && (
                  <Alert bsStyle="danger">This email address is already used</Alert>
                )}

              <UserInputField field="emailchecked" type="checkbox" label="Email Validated" />
              <UserInputField
                field="password"
                type="password"
                label="Password"
                placeholder="Password"
              />
              <UserInputField
                field="firstname"
                type="text"
                label="First Name"
                placeholder="First Name"
              />
              <UserInputField
                field="lastname"
                type="text"
                label="Last Name"
                placeholder="Last Name"
              />
              <UserInputField field="signupdate" type="datetime-local" label="Signup Date" />
              <UserInputField field="birthdate" type="date" label="Birthdate" />
              <UserInputField field="ip" type="text" label="IP" placeholder="IP" />

              {savingError && (
                <Alert bsStyle="danger">An error occured: {savingError.message}</Alert>
              )}
            </Col>

            <Col md={5} mdOffset={1}>
              <Panel header={'Level'} collapsible>
                <UserInputField field="van_level" type="number" label="Van Level" />
              </Panel>

              <Panel header={'Resources'} collapsible>
                <UserInputField field="resource_antidote" type="number" label="Antidote" />
                <UserInputField field="resource_gas" type="number" label="Gas" />
                <UserInputField field="resource_brick" type="number" label="Brick" />
                <UserInputField field="resource_food" type="number" label="Food" />
                <UserInputField field="resource_metal" type="number" label="Metal" />
                <UserInputField field="resource_wood" type="number" label="Wood" />
                <UserInputField
                  field="resources_collected_time"
                  type="datetime-local"
                  label="Last collection"
                />
              </Panel>

              <Panel header={'Truck'} collapsible>
                <UserInputField field="resource_brick_truck" type="number" label="Brick" />
                <UserInputField field="resource_food_truck" type="number" label="Food" />
                <UserInputField field="resource_metal_truck" type="number" label="Metal" />
                <UserInputField field="resource_wood_truck" type="number" label="Wood" />
                <UserInputField
                  field="resources_truck_update_time"
                  type="datetime-local"
                  label="Truck Update"
                />
              </Panel>
            </Col>
          </Row>
        </Panel>

        {this.renderConfirmDelete()}
      </Form>
    )
  }

  private renderConfirmDelete() {
    const { userEdit: { userData } } = this.props
    if (!userData) return null
    const { id, username } = userData
    if (!this.state.confirmDelete || !username) return null

    return (
      <Modal show backdrop onHide={this.handleCancelDelete}>
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure to delete this user?</p>
          <ul className="list-users">
            <li>
              <strong>{username}</strong>
              <br />
              Id: {id}
            </li>
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.handleCancelDelete}>Cancel</Button>
          <Button onClick={this.handleConfirmDelete} bsStyle="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  private handleReset = () => this.props.resetUserForm()

  private handleDelete = () => this.setState({ confirmDelete: true })
  private handleCancelDelete = () => this.setState({ confirmDelete: false })

  private handleConfirmDelete = () => {
    const { userEdit: { id } } = this.props
    if (!id) return
    this.props.disableByIds([id], true)
    this.setState({ confirmDelete: false })
  }

  private handleToggleDisable = () => {
    const { userEdit: { userForm } } = this.props
    if (!userForm) return
    const disabled = userForm.disabled ? null : moment().toISOString()
    this.props.updateUserForm({ disabled })
    this.props.saveUserForm()
  }
}

const mapStateToProps = ({ userEdit, userDelete }: IRootState) => ({
  userEdit,
  deleting: userDelete.loading
})

const mapDispatchToProps = {
  updateUserForm,
  resetUserForm,
  saveUserForm,
  disableByIds
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)
