import * as React from 'react'
import SideBarLink from './SideBarLink'

const SideBar = () => (
  <aside id="sidebar">
    <div className="sidenav-outer">
      <div className="side-menu">
        <ul className="nav nav-stacked sidenav">
          <SideBarLink to="/dashboard/" icon="home">
            Home
          </SideBarLink>
          <SideBarLink to="/dashboard/users" icon="user">
            Users
          </SideBarLink>
          <SideBarLink to="/dashboard/battles" icon="fire">
            Battles
          </SideBarLink>
        </ul>
      </div>
    </div>
  </aside>
)

export default SideBar
