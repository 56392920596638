import { socketEmit } from '../../../../redux/socket_actions'

// Actions types
export const actions = {
  USER_DELETING: 'USER_DELETING',
  USER_DELETING_ERROR: 'USER_DELETING_ERROR',
  USER_DELETED: 'USER_DELETED'
}

export const disableByIds = (ids: string[], forceDelete: boolean = false) => dispatch => {
  forceDelete = !!forceDelete
  dispatch({ type: actions.USER_DELETING })
  return socketEmit('Users.disableByIds', { ids, delete: forceDelete })
    .then(() => dispatch({ type: actions.USER_DELETED, ids, forceDelete }))
    .catch(error => dispatch({ type: actions.USER_DELETING_ERROR, error }))
}
