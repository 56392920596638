import * as React from 'react'
import { connect } from 'react-redux'
import * as qs from 'qs'
import { RouteComponentProps } from 'react-router-dom'
// Components
import { Row, Col } from 'react-bootstrap'
import BattlesSearch from './BattlesSearch'
import BattlesList from './BattlesList'
import BattleEdit from './BattleEdit'
import Scroll from 'react-scroll'
// Actions
import { search, updateSearchForm } from './redux/battlesSearch_actions'
import { loadById } from './redux/battleEdit_actions'
import { IBattlesSearchForm } from './redux/battlesSearch_reducer'
import { IRootState } from '../../../redux'

interface IParams {
  battleId?: string
}

interface IProps extends RouteComponentProps<IParams> {
  searchData: IBattlesSearchForm | null
  battleId: string | null
  updateSearchForm: typeof updateSearchForm
  search: typeof search
  loadById: typeof loadById
}

class BattlesPage extends React.PureComponent<IProps> {
  public componentWillMount() {
    this.applyLocationQuery(this.props.location.search)
    this.applyParams(this.props.match.params)
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.applyLocationQuery(nextProps.location.search)
    }
    if (this.props.match.url !== nextProps.match.url) {
      this.applyParams(nextProps.match.params)
    }
  }

  public render() {
    return (
      <div>
        <Row>
          <Col md={12} lg={5}>
            <BattlesSearch />
            <BattlesList />
          </Col>
          <Col md={12} lg={7}>
            <BattleEdit />
          </Col>
        </Row>
      </div>
    )
  }

  private applyLocationQuery(locationSearch) {
    const locationQuery = qs.parse(locationSearch.substr(1))
    if (!locationQuery || typeof locationQuery.query === 'undefined') return
    const { searchData } = this.props
    const searchForm = {
      query: locationQuery.query,
      page: parseInt(locationQuery.page, 10),
      limit: parseInt(locationQuery.limit, 10),
      orderBy: locationQuery.orderBy,
      startedFrom: locationQuery.startedFrom,
      startedTo: locationQuery.startedTo
    }

    // Don't apply if same query
    if (
      searchData &&
      searchForm.query === searchData.query &&
      searchForm.page === searchData.page &&
      searchForm.limit === searchData.limit &&
      searchForm.orderBy === searchData.orderBy &&
      searchForm.startedFrom === searchData.startedFrom &&
      searchForm.startedTo === searchData.startedTo
    )
      return

    // Update Form
    this.props.updateSearchForm(searchForm)
    // Send search
    this.props.search(searchForm)
  }

  private applyParams(params) {
    if (!params || !params.battleId || params.battleId === this.props.battleId) return
    // Load Battle Edition
    this.props.loadById(params.battleId)
    // Scroll to Panel
    Scroll.animateScroll.scrollToTop({
      duration: 300,
      smooth: true
    })
  }
}

const mapStateToProps = ({ battlesSearch, battleEdit }: IRootState) => ({
  searchData: battlesSearch.searchData,
  battleId: battleEdit.id
})

const mapDispatchToProps = {
  updateSearchForm,
  search,
  loadById
}

export default connect(mapStateToProps, mapDispatchToProps)(BattlesPage)
