import createReducer from '../../../../createReducer'
import { actions } from './userEdit_actions'
import { actions as actionsDelete } from './userDelete_actions'
import { IUser } from '@cityinvaders/dtos'

export interface IUserEditState {
  id: string | null
  userData: IUser | null
  userForm: IUser | null
  loading: boolean
  saving: boolean
  loadingError: Error | null
  savingError: Error | null
}

const initialState: IUserEditState = {
  id: null,
  userData: null,
  userForm: null,
  loading: false,
  saving: false,
  loadingError: null,
  savingError: null
}

export default createReducer(initialState, {
  [actions.USER_EDIT_UPDATE_FORM]: (state, { data }) => ({
    ...state,
    userForm: { ...state.userForm, ...data }
  }),
  [actions.USER_EDIT_RESET]: state => ({ ...state, userForm: state.userData }),
  [actions.USER_EDIT_LOADING]: (state, { id }) => ({
    ...state,
    loading: true,
    loadingError: null,
    savingError: null,
    id
  }),
  [actions.USER_EDIT_LOADED]: (state, { user }) => ({
    ...state,
    loading: false,
    userData: { ...user },
    userForm: { ...user }
  }),
  [actions.USER_EDIT_LOADING_ERROR]: (state, { error }) => ({
    ...state,
    loading: false,
    loadingError: error
  }),
  [actions.USER_SAVING]: (state, { id }) => ({ ...state, saving: true, savingError: null, id }),
  [actions.USER_SAVED]: (state, { user }) => ({ ...state, saving: false, userData: { ...user } }),
  [actions.USER_SAVING_ERROR]: (state, { error }) => ({
    ...state,
    saving: false,
    savingError: error
  }),

  [actionsDelete.USER_DELETED]: (state, { ids, forceDelete }) => {
    if (ids.indexOf(state.id) === -1) return state
    const { userForm, userData } = state

    // User deleted => loading error
    if (forceDelete) {
      const loadingError = new Error('User has been deleted.')
      return { ...state, userForm: null, userData: null, loadingError }
    }

    // User disabled
    const disabled = new Date().toISOString()
    return { ...state, userForm: { ...userForm, disabled }, userData: { ...userData, disabled } }
  }
})
