import * as React from 'react'
import { connect } from 'react-redux'
import * as qs from 'qs'
// Components
import { Row, Col } from 'react-bootstrap'
import UsersSearch from './UsersSearch'
import UsersList from './UsersList'
import UserEdit from './UserEdit'
import Scroll from 'react-scroll'
// Actions
import { search, updateSearchForm } from './redux/usersSearch_actions'
import { loadById } from './redux/userEdit_actions'
import { RouteComponentProps } from 'react-router-dom'
import { IUsersSearchForm } from './redux/usersSearch_reducer'
import { IRootState } from '../../../redux'

interface IParams {
  userId?: string
}

interface IProps extends RouteComponentProps<IParams> {
  searchData: IUsersSearchForm
  userId: string
  updateSearchForm: typeof updateSearchForm
  search: typeof search
  loadById: typeof loadById
}

class UsersPage extends React.PureComponent<IProps> {
  public componentWillMount() {
    this.applyLocationQuery(this.props.location.search)
    this.applyParams(this.props.match.params)
  }

  public componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.applyLocationQuery(nextProps.location.search)
    }
    if (this.props.match.url !== nextProps.match.url) {
      this.applyParams(nextProps.match.params)
    }
  }

  public render() {
    return (
      <div>
        <Row>
          <Col md={12} lg={5}>
            <UsersSearch />
            <UsersList />
          </Col>
          <Col md={12} lg={7}>
            <UserEdit />
          </Col>
        </Row>
      </div>
    )
  }

  private applyLocationQuery(locationSearch: string) {
    const locationQuery = qs.parse(locationSearch.substr(1))
    if (!locationQuery || typeof locationQuery.query === 'undefined') return
    const { searchData } = this.props
    const searchForm = {
      query: locationQuery.query,
      page: parseInt(locationQuery.page, 10),
      limit: parseInt(locationQuery.limit, 10),
      orderBy: locationQuery.orderBy,
      includeDisabledUsers: typeof locationQuery.includeDisabledUsers !== 'undefined'
    }

    // Don't apply if same query
    if (
      searchData &&
      searchForm.query === searchData.query &&
      searchForm.page === searchData.page &&
      searchForm.limit === searchData.limit &&
      searchForm.orderBy === searchData.orderBy &&
      searchForm.includeDisabledUsers === searchData.includeDisabledUsers
    )
      return

    // Update Form
    this.props.updateSearchForm(searchForm)
    // Send search
    this.props.search(searchForm)
  }

  private applyParams(params: IParams) {
    if (!params || !params.userId || params.userId === this.props.userId) return
    // Load User Edition
    this.props.loadById(params.userId)
    // Scroll to Panel
    Scroll.animateScroll.scrollToTop({
      duration: 300,
      smooth: true
    })
  }
}

const mapStateToProps = ({ usersSearch, userEdit }: IRootState) => ({
  searchData: usersSearch.searchData,
  userId: userEdit.id
})

const mapDispatchToProps = {
  updateSearchForm,
  search,
  loadById
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
