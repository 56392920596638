import { socketEmit } from '../../../../redux/socket_actions'

// Actions types
export const actions = {
  USER_COLLECTION_LOADING: 'USER_LOADING',
  USER_COLLECTION_LOADING_ERROR: 'USER_LOADING_ERROR',
  USER_COLLECTION_LOADED: 'USER_LOADED'
}

export const loadById = (id: string) => dispatch => {
  dispatch({ type: actions.USER_COLLECTION_LOADING, id })
  return socketEmit('Users.getById', { id })
    .then(response => dispatch({ type: actions.USER_COLLECTION_LOADED, user: response.users[0] }))
    .catch(error => dispatch({ type: actions.USER_COLLECTION_LOADING_ERROR, id, error }))
}
