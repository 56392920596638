import * as React from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
// Components
import {
  Panel,
  Checkbox,
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  Pagination,
  DropdownButton,
  MenuItem,
  Label,
  Modal
} from 'react-bootstrap'
import CheckboxIndeterminate from '../../common/CheckboxIndeterminate'
// Actions
import {
  updateSearchForm,
  selectToggleById,
  selectToggleAll,
  changeUrl
} from './redux/usersSearch_actions'
import { disableByIds } from './redux/userDelete_actions'
import { IUsersSearchForm, ISearchCollectionUser } from './redux/usersSearch_reducer'
import { IRootState } from '../../../redux'

const limits = [25, 50, 100, 500]

interface IProps {
  searchData: IUsersSearchForm
  users: ISearchCollectionUser[]
  totalCount: number
  userId: string
  changeUrl: typeof changeUrl
  updateSearchForm: typeof updateSearchForm
  selectToggleById: typeof selectToggleById
  selectToggleAll: typeof selectToggleAll
  disableByIds: typeof disableByIds
}

interface IState {
  confirmDeleteIds: string[]
}

class UsersList extends React.PureComponent<IProps, IState> {
  public state: IState = {
    confirmDeleteIds: []
  }

  public render() {
    const { searchData, users, totalCount } = this.props
    if (!users || !searchData) return null

    return (
      <form>
        <Panel
          bsStyle="primary"
          header={
            <span>
              <span className="glyphicon glyphicon-list" style={{ marginRight: 15 }} />
              Users Found: &quot;{searchData.query}&quot; ({totalCount})
            </span>
          }
          footer={
            totalCount !== 0 && (
              <div>
                <Button bsStyle="danger" onClick={this.handleDelete}>
                  Delete
                </Button>
                <Button bsStyle="warning" onClick={this.handleDisable}>
                  Disable
                </Button>
              </div>
            )
          }
        >
          {totalCount === 0 ? <span>No user was found with this query.</span> : this.renderList()}
        </Panel>

        {this.renderConfirmDelete()}
      </form>
    )
  }

  public renderList() {
    const { searchData, users, totalCount, userId } = this.props
    const dropdownLimits =
      limits.indexOf(searchData.limit) !== -1
        ? limits
        : [...limits, searchData.limit].sort((a, b) => a - b)
    const nSelectedUsers = users.reduce((count, user) => count + (user.selected ? 1 : 0), 0)

    return (
      <div className="table-wide">
        <Table striped responsive>
          <thead>
            <tr>
              <th style={{ width: 35 }}>
                <CheckboxIndeterminate
                  style={{ margin: 0 }}
                  checked={nSelectedUsers !== 0}
                  indeterminate={nSelectedUsers !== 0 && nSelectedUsers !== users.length}
                  onChange={this.props.selectToggleAll}
                />
              </th>
              <th className="col-md-4">Username</th>
              <th>Email</th>
              <th className="col-md-3">
                <a
                  onClick={e => {
                    e.preventDefault()
                    this.handleOrderByChange('signupdate')
                  }}
                >
                  Signup date{' '}
                  {searchData.orderBy === 'signupdate' && (
                    <i className="glyphicon glyphicon-chevron-down" />
                  )}
                  {searchData.orderBy === '-signupdate' && (
                    <i className="glyphicon glyphicon-chevron-up" />
                  )}
                </a>
              </th>
              <th style={{ width: 35 }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr
                key={'user-' + user.id}
                style={{ textDecoration: user.deleted ? 'line-through' : '' }}
                className={user.id === userId ? 'active' : ''}
              >
                <td>
                  <Checkbox
                    style={{ margin: 0 }}
                    checked={!!user.selected}
                    onChange={() => this.props.selectToggleById(user.id)}
                  />
                </td>
                <td>
                  {user.username}
                  {user.admin && (
                    <Label bsStyle="danger" style={{ marginLeft: 5 }}>
                      Admin
                    </Label>
                  )}
                  {user.disabled && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={'tooltip-user-disabled' + user.id}>
                          {moment(user.disabled).format('LLL')}
                        </Tooltip>
                      }
                    >
                      <Label bsStyle="default" style={{ marginLeft: 5 }}>
                        Disabled
                      </Label>
                    </OverlayTrigger>
                  )}
                </td>
                <td>{user.email}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={'tooltip-user-signup' + user.id}>
                        {moment(user.signupdate).format('LLL')}
                      </Tooltip>
                    }
                  >
                    <span>
                      {moment(user.signupdate).fromNow()}
                      <i className="glyphicon glyphicon-info-sign" style={{ marginLeft: 5 }} />
                    </span>
                  </OverlayTrigger>
                </td>
                <td>
                  {!user.deleted && (
                    <Button onClick={() => this.handleShowEdit(user.id)}>
                      <i className="glyphicon glyphicon-pencil" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {totalCount > dropdownLimits[0] && (
          <DropdownButton
            title={searchData.limit.toString()}
            id="users-number-limit"
            onSelect={this.handleLimitChange}
          >
            {dropdownLimits.map(limit => (
              <MenuItem key={'limit-' + limit} eventKey={limit}>
                {limit}
              </MenuItem>
            ))}
          </DropdownButton>
        )}

        {totalCount > users.length && (
          <Pagination
            first
            last
            ellipsis
            boundaryLinks
            items={Math.ceil(totalCount / searchData.limit)}
            maxButtons={5}
            activePage={searchData.page}
            onSelect={this.handlePageChange}
            style={{ margin: 0, float: 'right' }}
          />
        )}
      </div>
    )
  }

  public renderConfirmDelete() {
    const ids = this.state.confirmDeleteIds
    if (ids.length === 0) return null
    const users = this.props.users.filter(user => ids.indexOf(user.id) !== -1)

    return (
      <Modal show backdrop onHide={this.handleCancelDelete}>
        <Modal.Header>
          <Modal.Title>Delete Users</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure to delete these users:</p>
          <ul className="list-users">
            {users.map(user => (
              <li key={user.id}>
                <strong>{user.username}</strong>
                <br />
                {'Id: ' + user.id}
              </li>
            ))}
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.handleCancelDelete}>Cancel</Button>
          <Button onClick={this.handleConfirmDelete} bsStyle="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  private handlePageChange = (page) => {
    const { searchData } = this.props
    if (page === searchData.page) return
    this.props.updateSearchForm({ ...searchData, page })
    this.props.changeUrl()
  }

  private handleLimitChange = (limit) => {
    const { searchData } = this.props
    if (limit === searchData.limit) return
    this.props.updateSearchForm({ ...searchData, limit })
    this.props.changeUrl()
  }

  private handleOrderByChange = (field: string) => {
    const { searchData } = this.props
    // If same field, inverse order
    const orderBy =
      searchData.orderBy && field === searchData.orderBy.replace(/^-/, '')
        ? /^-/.test(searchData.orderBy) ? field : '-' + field
        : field
    this.props.updateSearchForm({ ...searchData, orderBy })
    this.props.changeUrl()
  }

  private handleShowEdit = (userId: string) => {
    if (userId === this.props.userId) return
    this.props.changeUrl(userId)
  }

  private handleDelete = () => this.setState({ confirmDeleteIds: this.getSelectedUsersIds() })
  private handleCancelDelete = () => this.setState({ confirmDeleteIds: [] })

  private handleConfirmDelete = () => {
    const ids = this.state.confirmDeleteIds
    if (ids.length === 0) return
    this.props.disableByIds(ids, true)
    this.setState({ confirmDeleteIds: [] })
  }

  private handleDisable = () => {
    const ids = this.getSelectedUsersIds()
    if (ids.length === 0) return
    this.props.disableByIds(ids)
  }

  private getSelectedUsersIds = () => this.props.users.filter(u => u.selected).map(u => u.id)
}

const mapStateToProps = ({ usersSearch, userEdit }: IRootState) => ({
  searchData: usersSearch.searchData,
  users: usersSearch.users,
  totalCount: usersSearch.totalCount,
  userId: userEdit.id
})

const mapDispatchToProps = {
  changeUrl,
  updateSearchForm,
  selectToggleById,
  selectToggleAll,
  disableByIds
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)
