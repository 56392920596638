import * as React from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
// Components
import {
  Panel,
  Checkbox,
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  Pagination,
  DropdownButton,
  MenuItem,
  Modal
} from 'react-bootstrap'
import CheckboxIndeterminate from '../../common/CheckboxIndeterminate'
import UserLink from '../Users/UserLink'
// Actions
import {
  updateSearchForm,
  selectToggleById,
  selectToggleAll,
  changeUrl
} from './redux/battlesSearch_actions'
import { deleteByIds } from './redux/battleDelete_actions'
import { ISearchCollectionBattle, IBattlesSearchForm } from './redux/battlesSearch_reducer'
import { IRootState } from '../../../redux'

const limits = [25, 50, 100, 500]

interface IProps {
  searchData: IBattlesSearchForm | null
  battles: ISearchCollectionBattle[] | null
  totalCount: number
  battleId: string | null
  changeUrl: typeof changeUrl
  updateSearchForm: typeof updateSearchForm
  selectToggleById: typeof selectToggleById
  selectToggleAll: typeof selectToggleAll
  deleteByIds: typeof deleteByIds
}

interface IState {
  confirmDeleteIds: string[]
}

class BattlesList extends React.PureComponent<IProps, IState> {
  public state: IState = {
    confirmDeleteIds: []
  }

  public render() {
    const { searchData, battles, totalCount } = this.props
    if (!battles || !searchData) return null

    return (
      <form>
        <Panel
          bsStyle="primary"
          header={
            <span>
              <span className="glyphicon glyphicon-list" style={{ marginRight: 15 }} />
              Battles Found: &quot;{searchData.query}&quot; ({totalCount})
            </span>
          }
          footer={
            totalCount !== 0 && (
              <div>
                <Button bsStyle="danger" onClick={this.handleDelete}>
                  Delete
                </Button>
              </div>
            )
          }
        >
          {totalCount === 0 ? <span>No battle was found with this query.</span> : this.renderList()}
        </Panel>

        {this.renderConfirmDelete()}
      </form>
    )
  }

  public renderList() {
    const { searchData, battles, totalCount, battleId } = this.props
    if (!battles || !searchData) return null
    const dropdownLimits =
      limits.indexOf(searchData.limit) !== -1
        ? limits
        : [...limits, searchData.limit].sort((a, b) => a - b)
    const nSelectedBattles = battles.reduce((count, battle) => count + (battle.selected ? 1 : 0), 0)

    return (
      <div className="table-wide">
        <Table striped responsive>
          <thead>
            <tr>
              <th style={{ width: 35 }}>
                <CheckboxIndeterminate
                  style={{ margin: 0 }}
                  checked={nSelectedBattles !== 0}
                  indeterminate={nSelectedBattles !== 0 && nSelectedBattles !== battles.length}
                  onChange={this.props.selectToggleAll}
                />
              </th>
              <th className="col-md-4">Users</th>
              <th>Map</th>
              <th>Goal</th>
              <th className="col-md-3">
                <a
                  onClick={e => {
                    e.preventDefault()
                    this.handleOrderByChange('start_time')
                  }}
                >
                  Start{' '}
                  {searchData.orderBy === 'start_time' && (
                    <i className="glyphicon glyphicon-chevron-down" />
                  )}
                  {searchData.orderBy === '-start_time' && (
                    <i className="glyphicon glyphicon-chevron-up" />
                  )}
                </a>
              </th>
              <th className="col-md-3">
                <a
                  onClick={e => {
                    e.preventDefault()
                    this.handleOrderByChange('end_time')
                  }}
                >
                  End{' '}
                  {searchData.orderBy === 'end_time' && (
                    <i className="glyphicon glyphicon-chevron-down" />
                  )}
                  {searchData.orderBy === '-end_time' && (
                    <i className="glyphicon glyphicon-chevron-up" />
                  )}
                </a>
              </th>
              <th style={{ width: 35 }}>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {battles.map(battle => (
              <tr
                key={'battle-' + battle.id}
                style={{ textDecoration: battle.deleted ? 'line-through' : '' }}
                className={battle.id === battleId ? 'active' : ''}
              >
                <td>
                  <Checkbox
                    style={{ margin: 0 }}
                    checked={!!battle.selected}
                    onChange={() => this.props.selectToggleById(battle.id)}
                  />
                </td>
                <td>
                  <ul>
                    {battle.users.map((user, i) => (
                      <li key={'link-' + i}>
                        <UserLink id={user.id} username={user.username} />
                      </li>
                    ))}
                  </ul>
                </td>
                <td>{battle.map_name}</td>
                <td>{battle.goal}</td>
                <td>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={'tooltip-battle-start_time' + battle.id}>
                        {moment(battle.start_time).format('LLL')}
                      </Tooltip>
                    }
                  >
                    <span>
                      {moment(battle.start_time).fromNow()}
                      <i className="glyphicon glyphicon-info-sign" style={{ marginLeft: 5 }} />
                    </span>
                  </OverlayTrigger>
                </td>
                <td>
                  {battle.end_time && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={'tooltip-battle-end_time' + battle.id}>
                          {moment(battle.end_time).format('LLL')}
                        </Tooltip>
                      }
                    >
                      <span>
                        {moment(battle.end_time).fromNow()}
                        <i className="glyphicon glyphicon-info-sign" style={{ marginLeft: 5 }} />
                      </span>
                    </OverlayTrigger>
                  )}
                </td>
                <td>
                  {!battle.deleted && (
                    <Button onClick={() => this.handleShowEdit(battle.id)}>
                      <i className="glyphicon glyphicon-pencil" />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {totalCount > dropdownLimits[0] && (
          <DropdownButton
            title={searchData.limit.toString()}
            id="battles-number-limit"
            onSelect={this.handleLimitChange}
          >
            {dropdownLimits.map(limit => (
              <MenuItem key={'limit-' + limit} eventKey={limit}>
                {limit}
              </MenuItem>
            ))}
          </DropdownButton>
        )}

        {totalCount > battles.length && (
          <Pagination
            first
            last
            ellipsis
            boundaryLinks
            items={Math.ceil(totalCount / searchData.limit)}
            maxButtons={5}
            activePage={searchData.page}
            onSelect={this.handlePageChange}
            style={{ margin: 0, float: 'right' }}
          />
        )}
      </div>
    )
  }

  public renderConfirmDelete() {
    const { battles } = this.props
    if (!battles) return null
    const ids = this.state.confirmDeleteIds
    if (ids.length === 0) return null

    const battlesIds = battles
      .filter(battle => ids.indexOf(battle.id) !== -1)
      .map(battle => battle.id)

    return (
      <Modal show backdrop onHide={this.handleCancelDelete}>
        <Modal.Header>
          <Modal.Title>Delete Battles</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure to delete these battles:</p>
          <ul>
            {battlesIds.map(id => (
              <li key={id}>
                <strong>{id}</strong>
              </li>
            ))}
          </ul>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.handleCancelDelete}>Cancel</Button>
          <Button onClick={this.handleConfirmDelete} bsStyle="danger">
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  private handlePageChange = page => {
    const { searchData } = this.props
    if (!searchData || page === searchData.page) return
    this.props.updateSearchForm({ ...searchData, page })
    this.props.changeUrl()
  }

  private handleLimitChange = limit => {
    const { searchData } = this.props
    if (!searchData || limit === searchData.limit) return
    this.props.updateSearchForm({ ...searchData, limit })
    this.props.changeUrl()
  }

  private handleOrderByChange = field => {
    const { searchData } = this.props
    if (!searchData) return
    // If same field, inverse order
    const orderBy =
      searchData.orderBy && field === searchData.orderBy.replace(/^-/, '')
        ? /^-/.test(searchData.orderBy) ? field : '-' + field
        : field
    this.props.updateSearchForm({ ...searchData, orderBy })
    this.props.changeUrl()
  }

  private handleShowEdit = battleId => {
    if (battleId === this.props.battleId) return
    this.props.changeUrl(battleId)
  }

  private handleDelete = () => this.setState({ confirmDeleteIds: this.getSelectedBattlesIds() })
  private handleCancelDelete = () => this.setState({ confirmDeleteIds: [] })

  private handleConfirmDelete = () => {
    const ids = this.state.confirmDeleteIds
    if (ids.length === 0) return
    this.props.deleteByIds(ids)
    this.setState({ confirmDeleteIds: [] })
  }

  private getSelectedBattlesIds = () =>
    this.props.battles ? this.props.battles.filter(u => u.selected).map(u => u.id) : []
}

const mapStateToProps = ({ battlesSearch, battleEdit }: IRootState) => ({
  searchData: battlesSearch.searchData,
  battles: battlesSearch.battles,
  totalCount: battlesSearch.totalCount,
  battleId: battleEdit.id
})

const mapDispatchToProps = {
  changeUrl,
  updateSearchForm,
  selectToggleById,
  selectToggleAll,
  deleteByIds
}

export default connect(mapStateToProps, mapDispatchToProps)(BattlesList)
