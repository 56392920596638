import { socketEmit } from '../../../../redux/socket_actions'

// Actions types
export const actions = {
  HOME_BATTLES_LOADING: 'HOME_BATTLES_LOADING',
  HOME_BATTLES_LOADED: 'HOME_BATTLES_LOADED',
  HOME_BATTLES_ERROR: 'HOME_BATTLES_ERROR',
  HOME_BATTLES_UPDATE_SEARCH_FORM: 'HOME_BATTLES_UPDATE_SEARCH_FORM'
}

export const search = (searchForm) => (dispatch) => {
  dispatch({ type: actions.HOME_BATTLES_LOADING, searchData: searchForm })
  return socketEmit('Battles.search', searchForm)
    .then((response) => dispatch({ type: actions.HOME_BATTLES_LOADED, battles: response.battles }))
    .catch((searchError) => dispatch({ type: actions.HOME_BATTLES_ERROR, searchError }))
}
