import usersCollection, { IUsersCollectionState } from './usersCollection_reducer'
import userDelete, { IUserDeleteState } from './userDelete_reducer'
import userEdit, { IUserEditState } from './userEdit_reducer'
import usersSearch, { IUsersSearchState } from './usersSearch_reducer'

export default {
  usersCollection,
  userDelete,
  userEdit,
  usersSearch
}

export interface IUsersState {
  usersCollection: IUsersCollectionState
  userDelete: IUserDeleteState
  userEdit: IUserEditState
  usersSearch: IUsersSearchState
}
