import * as React from 'react'
import { connect } from 'react-redux'
// Components
import { NavDropdown, NavItem } from 'react-bootstrap'
import * as icon from '../../../assets/images/icon_50.png'
// Actions
import { logout } from '../../../redux/account_actions'
import { loadCurrentUser } from '../Users/redux/usersSearch_actions'
import { IRootState } from '../../../redux'

interface IProps {
  onToggleMenu: () => void
}

interface IReduxProps {
  authenticated: boolean
  username: string
  logout: typeof logout
  loadCurrentUser: typeof loadCurrentUser
}

class TopNav extends React.PureComponent<IProps & IReduxProps> {
  public render() {
    const { authenticated, username, onToggleMenu } = this.props
    if (!authenticated) return null

    return (
      <nav className="navbar topnav-navbar navbar-fixed-top" role="navigation">
        <div className="navbar-header text-center">
          <button type="button" className="navbar-toggle" onClick={onToggleMenu}>
            <span className="sr-only">Toggle navigation</span>
            <img src={icon} alt="" />
          </button>
          <span className="navbar-brand">City Invaders</span>
        </div>

        <div className="collapse navbar-collapse">
          <form className="navbar-form navbar-left" role="search">
            <span className="glyphicon glyphicon-search" />

            <div className="form-group">
              <input type="text" className="form-control" placeholder="" />
            </div>
          </form>

          <ul className="nav navbar-nav pull-right navbar-right">
            <NavDropdown
              id="profile-dropdown"
              eventKey={4}
              title={username}
              noCaret
              className="dropdown admin-dropdown"
            >
              <NavItem onClick={this.handleLoadUser}>Edit User</NavItem>
              <NavItem onClick={this.handleLogout}>Log out</NavItem>
            </NavDropdown>
          </ul>
        </div>
      </nav>
    )
  }

  private handleLoadUser = () => this.props.loadCurrentUser()
  private handleLogout = () => this.props.logout()
}

const mapStateToProps = ({ account }: IRootState) => ({
  authenticated: account.user != null,
  username: account.user ? account.user.username : ''
})

const mapDispatchToProps = {
  logout,
  loadCurrentUser
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNav)
