import * as qs from 'qs'
import history from '../../../../history'
import { socketEmit } from '../../../../redux/socket_actions'
import { IUsersSearchForm } from './usersSearch_reducer'
import { IRootState } from '../../../../redux';

// Actions types
export const actions = {
  USERS_LOADING: 'USERS_LOADING',
  USERS_LOADED: 'USERS_LOADED',
  USERS_ERROR: 'USERS_ERROR',
  USERS_UPDATE_SEARCH_FORM: 'USERS_UPDATE_SEARCH_FORM',
  USERS_SELECT_TOGGLE_BY_ID: 'USERS_SELECT_TOGGLE_BY_ID',
  USERS_SELECT_TOGGLE_ALL: 'USERS_SELECT_TOGGLE_ALL'
}

export const updateSearchForm = (data: Partial<IUsersSearchForm>) => ({
  type: actions.USERS_UPDATE_SEARCH_FORM,
  data
})

export const search = (searchForm: IUsersSearchForm) => dispatch => {
  dispatch({ type: actions.USERS_LOADING, searchData: searchForm })
  return socketEmit('Users.search', searchForm)
    .then(response =>
      dispatch({
        type: actions.USERS_LOADED,
        users: response.users,
        totalCount: response.totalCount
      })
    )
    .catch(searchError => dispatch({ type: actions.USERS_ERROR, searchError }))
}

export const selectToggleById = (id: string) => ({ type: actions.USERS_SELECT_TOGGLE_BY_ID, id })
export const selectToggleAll = () => ({ type: actions.USERS_SELECT_TOGGLE_ALL })

// Push new URL to history with search form data and optional userId
// If userId is omitted, user id in URL doesn't change
export const changeUrl = (newUserId?: string) => (dispatch, getState: () => IRootState) => {
  const { searchForm } = getState().usersSearch
  const userId = typeof newUserId === 'string' ? newUserId : getState().userEdit.id
  const locationQuery = {
    query: searchForm.query,
    limit: searchForm.limit,
    page: searchForm.page,
    orderBy: searchForm.orderBy,
    ...(searchForm.includeDisabledUsers && {
      includeDisabledUsers: null
    })
  }
  history.push({
    pathname: '/dashboard/users' + (userId ? '/' + userId : ''),
    search: qs.stringify(locationQuery)
  })
}

export const loadCurrentUser = () => (dispatch, getState: () => IRootState) => {
  const user = getState().account.user
  if (user) return dispatch(changeUrl(user.id))
}
