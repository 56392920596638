import battleDelete, { IBattleDeleteState } from './battleDelete_reducer'
import battleEdit, { IBattleEditState } from './battleEdit_reducer'
import battlesSearch, { IBattlesSearchState } from './battlesSearch_reducer'

export default {
  battleDelete,
  battleEdit,
  battlesSearch
}

export interface IBattlesState {
  battleDelete: IBattleDeleteState
  battleEdit: IBattleEditState
  battlesSearch: IBattlesSearchState
}
