import * as React from 'react'
import { connect } from 'react-redux'
// Components
import { Panel, Button, FormControl, FormGroup, Alert } from 'react-bootstrap'
import LoadingSpinner from '../../common/LoadingSpinner'
// Actions
import { updateSearchForm, changeUrl } from './redux/battlesSearch_actions'
import { IBattlesSearchForm } from './redux/battlesSearch_reducer'
import { IRootState } from '../../../redux'

interface IProps {
  form: IBattlesSearchForm
  loading: boolean
  error: Error | null
  updateSearchForm: typeof updateSearchForm
  changeUrl: typeof changeUrl
}

class BattlesSearch extends React.PureComponent<IProps> {
  public render() {
    const { form, loading, error } = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <Panel
          bsStyle="primary"
          header={
            <span>
              <span className="glyphicon glyphicon-search" style={{ marginRight: 15 }} />
              Search Battles
            </span>
          }
          footer={
            <Button type="submit" bsSize="large" disabled={loading}>
              {loading && <LoadingSpinner opts={{ color: '#fff' }} style={{ marginRight: 10 }} />}
              Search
            </Button>
          }
        >
          <FormGroup bsSize="large">
            <FormControl
              type="text"
              placeholder="Battle ID / User ID / Username"
              className="underline"
              value={form.query}
              onChange={this.handleQueryChange}
            />
          </FormGroup>

          {error && (
            <Alert bsStyle="danger">
              {error.name}: {error.message}
            </Alert>
          )}
        </Panel>
      </form>
    )
  }

  private handleSubmit = e => {
    e.preventDefault()
    this.props.updateSearchForm({ page: 1 })
    this.props.changeUrl()
  }

  private handleQueryChange = e => this.props.updateSearchForm({ query: e.target.value })
}

const mapStateToProps = ({ battlesSearch }: IRootState) => ({
  form: battlesSearch.searchForm,
  loading: battlesSearch.loading,
  error: battlesSearch.searchError
})

const mapDispatchToProps = {
  updateSearchForm,
  changeUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(BattlesSearch)
