import createReducer from '../../../../createReducer'
import { actions } from './userDelete_actions'

export interface IUserDeleteState {
  loading: boolean
  error: Error | null
}

const initialState: IUserDeleteState = {
  loading: false,
  error: null
}

export default createReducer(initialState, {
  [actions.USER_DELETING]: state => ({ ...state, loading: true, error: null }),
  [actions.USER_DELETED]: state => ({ ...state, loading: false }),
  [actions.USER_DELETING_ERROR]: (state, { error }) => ({ ...state, loading: false, error })
})
