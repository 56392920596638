import * as React from 'react'
import { connect } from 'react-redux'
// Components
import { Panel, Button } from 'react-bootstrap'
import LoadingSpinner from '../../common/LoadingSpinner'
// Actions
import { changeUrl } from './redux/usersSearch_actions'
import { loadById } from './redux/usersCollection_actions'
import { IUser } from '@cityinvaders/dtos'
import { IRootState } from '../../../redux'

interface IProps {
  id: string
}

interface IReduxProps {
  user: IUser | null
  loading: boolean
  error: Error | null
  loadById: typeof loadById
  changeUrl: typeof changeUrl
}

class UserCard extends React.PureComponent<IProps & IReduxProps> {
  public componentWillMount() {
    this.load(this.props)
  }
  public componentWillReceiveProps(nextProps: IProps & IReduxProps) {
    this.load(nextProps)
  }

  public render() {
    const { id, user, loading, error } = this.props

    if (loading) {
      return (
        <Panel
          bsStyle="info"
          header={
            <span>
              <span className="glyphicon glyphicon-user" style={{ marginRight: 15 }} />
              User
            </span>
          }
        >
          <LoadingSpinner opts={{ scale: 0.12 }} style={{ marginRight: 10 }} />
          Loading
        </Panel>
      )
    }

    if (error) {
      return (
        <Panel
          bsStyle="danger"
          header={
            <span>
              <span className="glyphicon glyphicon-user" style={{ marginRight: 15 }} />
              {error.message === 'NOT_FOUND' ? 'User Not Found' : error.message}
            </span>
          }
        >
          <strong>User ID:</strong> {id}
        </Panel>
      )
    }

    if (!user) return null

    return (
      <Panel
        bsStyle="default"
        header={
          <span>
            <span className="glyphicon glyphicon-user" style={{ marginRight: 15 }} />
            {user.username}
          </span>
        }
      >
        Id: {user.id}
        <br />
        Email: {user.email}
        <br />
        <Button onClick={() => this.props.changeUrl(user.id)}>
          <i className="glyphicon glyphicon-pencil" /> Edit
        </Button>
      </Panel>
    )
  }

  private load(props: IProps & IReduxProps) {
    if (!props.user && !props.loading && !props.error) {
      props.loadById(props.id)
    }
  }
}

const mapStateToProps = ({ usersCollection }: IRootState, props: IProps) => {
  const user = usersCollection.find(u => u.id === props.id)
  return {
    user: user ? user.user : null,
    loading: user ? user.loading : false,
    error: user ? user.error : null
  }
}

const mapDispatchToProps = {
  loadById,
  changeUrl
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCard)
