import * as React from 'react'
import { Panel } from 'react-bootstrap'
import { connect } from 'react-redux'
import * as moment from 'moment'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { search } from './redux/homeBattles_actions'
import { IRootState } from '../../../redux'

const TIMEFRAME = 31 // in days, could be adjustable later

const prepareChart = battles => {
  // will hold the days for X axis on the chart
  const days: any[] = []
  const lastDay = moment().startOf('day')
  let day = moment().subtract(TIMEFRAME, 'days')

  while (day <= lastDay) {
    days.push({ name: day.format('DD/MM'), started: 0, finished: 0, bugged: 0 })
    day = day.clone().add(1, 'day')
  }

  return battles.reduce((dayCharts, battle) => {
    const dayData = dayCharts.find(
      dayChart => dayChart.name === moment(battle.start_time).format('DD/MM')
    )
    if (dayData) {
      // should always be there
      dayData.started += 1
      if (battle.end_time) dayData.finished += 1
      if (battle.bugged) dayData.bugged += 1
    }
    return dayCharts
  }, days)
}

interface IProps {
  battles: any[]
  search: typeof search
}

class HomeBattlesGraph extends React.Component<IProps> {
  public componentWillMount() {
    const searchForm = {
      limit: 1000,
      startedFrom: moment()
        .subtract(TIMEFRAME, 'days')
        .startOf('day'),
      startedTo: moment(),
      orderBy: 'start_time'
    }
    this.props.search(searchForm)
  }

  public render() {
    const data = prepareChart(this.props.battles)

    return (
      <Panel
        bsStyle="primary"
        header={
          <span>
            <span className="glyphicon glyphicon-stats" style={{ marginRight: 15 }} />
            Battles Graph
          </span>
        }
      >
        <ResponsiveContainer width="100%" height={600}>
          <LineChart height={600} data={data} margin={{ top: 15, right: 15, left: 15, bottom: 15 }}>
            <XAxis dataKey="name" />
            <YAxis label={{ value: 'number of battles', angle: -90, position: 'center' }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="started" stroke="#5bc0de" />
            <Line type="monotone" dataKey="finished" stroke="#5cb85c" />
            <Line type="monotone" dataKey="bugged" stroke="#de6764" />
          </LineChart>
        </ResponsiveContainer>
      </Panel>
    )
  }
}

const mapStateToProps = ({ homeBattles }: IRootState) => ({
  battles: homeBattles.battles
})

const mapDispatchToProps = {
  search
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeBattlesGraph)
